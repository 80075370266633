<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="row">
        <div class="col my-auto">
          <p class="dialog-title text-uppercase mb-0">Send Invites</p>
        </div>
        <div class="col my-auto">
          <img class="close-button"
               src="../../assets/images/close.png"
               alt="close-button"
               @click="$emit('close')"/>
        </div>
      </div>
    </div>

    <div class="dialog-content" style="overflow-y: auto; max-height: 560px;">
      <b-form-group class="mb-5">
        <multiselect class="user-search-select"
                     label="email"
                     v-model="value"
                     :options="options"
                     :multiple="true"
                     :close-on-select="false"
                     :clear-on-select="false"
                     :preserve-search="true"
                     track-by="email"
                     placeholder="Add Email Addresses"
                     :taggable="true"
                     @tag="addTag"
                     tag-placeholder="Add Email Address"
                     :showNoResults="false"
        >
        </multiselect>
      </b-form-group>
      <b-form-group class="mt-5 pt-5">
        <div class="text-right">
          <b-button class="btn btn-success btn-h3-small"
                    @click="sendInvites"
                    :disabled="!sendButtonEnabled"
                    type="submit">
            Send
          </b-button>
        </div>
      </b-form-group>

      <!-- Loading -->
      <loading :active.sync="isLoading"
               :is-full-page="true"
               background-color="#000"
               color="#068bcd"
               :opacity="0.5"
               :width="70"
               :height="70">
        <rainbow-loader></rainbow-loader>
      </loading>
    </div>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  const Multiselect = () => import('vue-multiselect');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');

  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name  : 'SendInvite',
    props : [],
    data() {
      return {
        userId    : this.$store.getters['user/user'].user_id,
        options   : [],
        value     : [],
        isLoading : false,
      };
    },
    components : {
      Loading,
      Multiselect,
      RainbowLoader,
    },
    computed : {
      sendButtonEnabled() {
        return this.value.length > 0;
      },
    },
    methods : {

      /**
       * Add email
       * @param email
       */
      addTag(email) {
        email = email.toLowerCase();
        // eslint-disable-next-line max-len
        const expression = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

        if (expression.test(String(email))) {
          // eslint-disable-next-line no-undef
          let match = _.find(this.value, (o) => {
            return o.email === email;
          });

          if (typeof (match) == 'undefined') {
            this.value.push({
              email : email,
            });
          }

          // eslint-disable-next-line no-undef
          match = _.find(this.options, (o) => {
            return o.email === email;
          });

          if (typeof (match) == 'undefined') {
            this.options.push({
              email : email,
            });
          }
        } else {
          this.$notify({
            group : 'notif',
            type  : 'error',
            text  : 'Invalid email address.',
          });
        }
      },

      /**
       * Send Invites
       */
      sendInvites(){
        this.isLoading = true;
        const emails = [];
        // eslint-disable-next-line no-undef
        _.each(this.value, (o) => {
          emails.push(o.email);
        });
        this.$http.post('api/user/invite', {
          userId : this.userId,
          emails : emails,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Email Invite successfully sent!',
          });
          const that = this;
          setTimeout(async function () {
            that.isLoading = false;
            that.$emit('close');
          }, 2000);
        }).catch(() => {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/send-classroom-invite";
</style>
